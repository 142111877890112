const LocationReviewsData = [
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Roy Anderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joshua M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "April Franklin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Megan M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Gary Randolph",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Charles B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Michelle Martin",
        "service_type": "Garage Door Services",
        "tech_name": "Teresa T.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Andrew Mcgrath",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lisa W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Linda Rogers",
        "service_type": "Garage Door Repair",
        "tech_name": "Jordan A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Dawn Hall",
        "service_type": "Garage Door Installation",
        "tech_name": "Jason J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Edward Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Crystal W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Hannah Singh",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Adam G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Megan Cook",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Austin C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Melissa Graham",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Jennifer Morris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gina W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Frank Acosta",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jacob G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Damon Wilson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Matthew M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Andre Barnes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Maria M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Emily Carter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Justin H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Michelle Hayes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Erica Campbell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexander C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Vincent Campbell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sherry L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Melissa Thomas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lauren L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brooke S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Samantha Ryan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michelle S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "John Beltran",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Nicholas F.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Melanie Turner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katie T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Glenn Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard V.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Samantha Stanley",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin K.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "James Romero",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Thomas S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Michaela Reid",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Audrey Porter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kenneth G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Anthony Pena",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Laura M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Hannah Pope",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Paul Kim",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Raven W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Shelby Franklin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tracy S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Matthew Villanueva",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kathy A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Christina Greene",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Maria J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Melissa Moore",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Lisa Larson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christine F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Stacey Grant",
        "service_type": "Garage Door Insulation",
        "tech_name": "Megan H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Martha Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.8,
        "customer_name": "Amy Cox",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tonya R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Mark Walker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Steven Porter",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Albert L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Peggy Bridges",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Hunter A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Donald Galloway",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gregory H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Lisa Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Terry C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Christopher Mora",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Fernando W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Jamie Nguyen",
        "service_type": "Garage Door Services",
        "tech_name": "Eugene B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Mcclure",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Christopher Gomez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karen R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "James Torres",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Blake S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Charles Scott",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Larry S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Charles Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Krista W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Patrick Juarez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carrie M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Alexander Mclaughlin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Pamela S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Jessica Garcia",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Summer G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Ricky Johnston",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bob L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Stephanie Blanchard",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mark S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Susan Phelps",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melissa H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Gina Garcia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeffrey D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Jenna Walsh",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Keith W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Travis Ross",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cynthia G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Victor Morse",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Kristine Kim",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tammy H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Thomas Norris",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bianca R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Charles Robbins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jacob A.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Cynthia Hill",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dale G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "William Warren",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alexis M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Richard Bryant",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stephanie M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Carolyn Hill",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Andrew Acevedo",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gary S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Suzanne Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Samantha Hudson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jason B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Jacob Morgan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Timothy S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Stephen Dennis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tammy C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Douglas Leon",
        "service_type": "Garage Door Repair",
        "tech_name": "Anita R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Kristy Moore",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Marcus B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Jamie Ramirez",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacqueline M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Matthew Becker II",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karen C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Nicholas Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Chelsea B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Mario Stafford",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Matthew C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Laura Hardin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jo B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Lucas Pineda",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Benjamin W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "William Mcdowell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Kelsey Coleman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Joshua Scott",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Ryan Cook",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melissa P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "John Rivera",
        "service_type": "Garage Door Insulation",
        "tech_name": "James A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Jeremiah Odonnell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Jennifer Frank",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Joshua Rich",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brenda A.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-09",
        "review_rate": 5.0,
        "customer_name": "Robert Coleman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffery V.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Rebecca Jacobson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Jason Fernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Leslie F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Richard Bradford",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lauren M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Alyssa Bates",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Ashley Brooks",
        "service_type": "Garage Door Insulation",
        "tech_name": "Edward I.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Chad Rodgers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jordan C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Debra Hayes",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joanna C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Andrew Holland",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lawrence W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Angela Sparks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Connie M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Kathleen Barnes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Paul L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Alexa Figueroa",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Catherine C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Corey Cook",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Christine Pena",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Timothy Cox",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Terri M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Rebecca Oliver",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Douglas B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Alexandra Byrd",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Edward H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Michelle Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Sandra Willis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shirley R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Emily Pratt",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Troy M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Karen Ortiz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Cynthia W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Leslie Green",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kayla M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Ryan Pena",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Daniel D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Melissa Fisher",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kathleen R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Christian Reed",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Mrs. Hannah Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Matthew R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Roberta Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Garrett Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Alicia Hayes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Corey M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Bryan Taylor",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mark S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Ryan Macias",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandy S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Sally Pineda",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Douglas R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Nicholas Brooks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tracy W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Miss Molly Brooks",
        "service_type": "Garage Door Off Track",
        "tech_name": "David T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Matthew Reynolds",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cindy H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Gregory Nichols",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erik H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Virginia Peters",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Austin F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Scott Rogers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Danielle M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "David Huynh",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jessica H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Mckenzie Irwin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Arthur B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Mr. Victor Cruz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angelica D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Melissa Rose",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael R.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Alfred Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashlee H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Karen Buckley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jade F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Benjamin Snyder",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Samuel H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Adrian Baker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Luke C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Danielle Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Erica Campbell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "William James",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julie M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Daniel Kane",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Larry M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Sanchez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Wendy L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Debbie Brown",
        "service_type": "Garage Door Services",
        "tech_name": "Elizabeth W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Chad Diaz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Steven Long",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sarah C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Christopher Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Tony Holt",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ashley G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Miguel Long",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jessica S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Jennifer Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Victoria Reed",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Melinda Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Pamela F.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Henry Parker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Lisa Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Maria D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Sean Gonzales",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicole J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Debbie Ochoa",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shelley H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Nathan Morales",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Denise C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Alicia Serrano",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert O.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Nathan Lee",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Henry F.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Dr. Douglas Barrett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rachel K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Julie Richardson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Justin N.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Sara Warner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Whitney P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "John Reed",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Geoffrey T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Floyd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Victoria Weaver",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Xavier S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Brian Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Vanessa Moore",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jody K.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "William Schroeder",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alyssa J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Robert Andersen",
        "service_type": "Garage Door Repair",
        "tech_name": "Evan N.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Cameron Friedman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Casey Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeanette C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Anthony Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shelley B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Daniel Phelps",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amy W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Timothy Davis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Valerie M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Michael Conner",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicole A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Mrs. Brianna Vargas DVM",
        "service_type": "Garage Door Repair",
        "tech_name": "Frank H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Mr. David Reyes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Deanna White",
        "service_type": "Garage Door Services",
        "tech_name": "Nicholas O.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Jonathan Wong",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dustin B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Anthony Waters",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Larry Murray",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Paul Mcdonald",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christina O.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Robert Hurley",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexa W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Christopher Mckinney",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Kenneth Mcbride",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Christopher Elliott",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Linda P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Betty Garrison",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Nichole Moreno MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Kyle Hill",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brian S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Jeff Lopez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Kristin Cox",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William K.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Linda Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bryan W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Tommy Garcia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tanya S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Michele Fields",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kathleen B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Johnny Sharp",
        "service_type": "Garage Door Repair",
        "tech_name": "John B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Nicole Dillon",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gregory L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Martin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chloe B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Sylvia Dennis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Stephanie Lynch",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Edward S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Robert Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ashley C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Luis Salazar",
        "service_type": "Garage Door Opener",
        "tech_name": "Alyssa C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Victor Cordova",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Susan T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Penny Lynch",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffrey C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Alice Wright",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Molly Hopkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mark T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "David Ochoa",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Janet M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Megan Morris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Shannon Holt",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Colleen S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Tammy Newman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brian D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Kimberly Foster",
        "service_type": "Garage Door Installation",
        "tech_name": "Sean L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Miss Martha Smith DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patricia T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Michael Collier MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joshua P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Anthony Griffin",
        "service_type": "Garage Door Services",
        "tech_name": "Molly D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Steven Harris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andrea K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Lindsey Franklin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Matthew Ayala",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jose K.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Charles Dunn",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Rebecca Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kelly T.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Joe Hodges",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christina L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "James Hanson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Sheri Hill",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Ruben Herrera",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Sandra Alexander",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Todd C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Alexandra Salazar",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Raymond Vazquez",
        "service_type": "Garage Door Repair",
        "tech_name": "Leslie B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Sara Burch",
        "service_type": "Garage Door Off Track",
        "tech_name": "Justin B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Wesley Austin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Bryan Porter",
        "service_type": "Garage Door Services",
        "tech_name": "Rhonda M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "James Rivera",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Melanie Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Rebecca W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Karen Perry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Steven V.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Daniel Sharp",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sean A.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Adriana Jensen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joshua B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Caitlin Rowe",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Andrea Wong",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cynthia S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Timothy Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Garrett W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Jeremy Holland",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Darrell Bradley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Karen M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "David Robinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sheri Y.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Matthew Ramos",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Christopher Diaz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Antonio L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Ashley Barnes",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Ian Bond",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Vanessa B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Adam Pineda",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ashley N.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Daniel Nelson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brent W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Mr. Dale Schwartz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelsey B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Shannon Benson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alisha R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Donna Thomas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Oscar B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Megan Clark",
        "service_type": "Garage Door Off Track",
        "tech_name": "James F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Gail Rios",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Laura S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Linda Grant",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stacy P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Annette Jefferson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gina C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Alexander Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Luis B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Stephanie Hodges",
        "service_type": "Garage Door Services",
        "tech_name": "Charles G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Melissa Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dana S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Brandon Hughes Jr.",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Morgan M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Sarah Hurst",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Autumn B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Kathryn Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Felicia G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Nathaniel Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maureen O.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Ashley Poole",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christine N.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Lori Sanders DDS",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Carol S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Kristi Lambert",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Angela Ford",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jason S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Alexandra Lopez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Johnny M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Peter Ellis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Andrea Parker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristin W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Scott Hoffman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chad M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Laura Contreras",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Jamie Coleman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Marcus Avila",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tony H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Billy Gordon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Kenneth Ramos",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Phillip D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Dale Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Seth M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Carol Douglas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alexis R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Audrey Russell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicole C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "William Salazar",
        "service_type": "Garage Door Repair",
        "tech_name": "Kari A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Nicholas Nguyen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Terri G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Haley Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Timothy Ramos MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bradley C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Stacy Obrien",
        "service_type": "Garage Door Insulation",
        "tech_name": "Paula S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Sherry Cole",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Reginald Payne",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Dorothy Hickman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Elizabeth W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Regina Reeves",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tonya W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Kayla Anderson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Chris W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Marcus Kelley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sean F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Danny Cook",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Jesus Fletcher",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Kelly Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Emily A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Daniel Burgess",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew K.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Terri Boyer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Wanda R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Dana Palmer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tabitha G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Brandy Russell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicholas H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Andrea Garner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Brianna Ross",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Robert Schneider",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Briana O.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Stephen Roberts",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Susan B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Rachel Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anthony R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Craig Dalton",
        "service_type": "Garage Door Services",
        "tech_name": "Danielle S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Jorge Hopkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dawn J.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Laura Turner",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Deanna Weiss",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jake M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Amanda Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Terry H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Julia Wright",
        "service_type": "Garage Door Insulation",
        "tech_name": "Keith S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Austin Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Jerry Glover",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeffery M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Jennifer Wade DVM",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrew W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Eugene Cannon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristin A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Mr. Richard Phillips",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Allison B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Alyssa White",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Allison M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Anthony Gates",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brian M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Shannon Spencer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Jack Doyle",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Aaron W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Theresa Castillo",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jillian M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Donald Cooper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Danielle H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Sara Roach",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Karen P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Kyle Taylor",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carolyn M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Jeffery Arellano",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Mr. Chris Williams DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Brent M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Erin Pham",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Carrie W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Jacob Carey",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lisa C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Robert Burke",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stacey J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.8,
        "customer_name": "Nicole Edwards",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Victor H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Caroline Turner",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Carlos Ramirez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mark R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Laura Dixon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Megan J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Ryan Hartman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeremiah M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Heidi Tyler",
        "service_type": "Garage Door Installation",
        "tech_name": "Meredith S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "David Ferguson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joseph P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Denise Greer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Miguel M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jon K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Walter Robinson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brittney C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Amber Lynch",
        "service_type": "Garage Door Installation",
        "tech_name": "James C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Justin Keith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Sharon Hunter",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica O.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Katie Tate",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Raymond F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Renee Reynolds",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Hayes",
        "service_type": "Garage Door Repair",
        "tech_name": "Hannah T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Christopher Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jessica C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Christopher Mills",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Deborah T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Jeremy Curry",
        "service_type": "Garage Door Installation",
        "tech_name": "Francisco S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Paul Stevens",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Danny Stone",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jared S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Christine Ramirez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bruce P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Christopher Watson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jacqueline L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Ryan Reed",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Shannon Hill",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dennis H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Christian Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alexandra V.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Erin Simpson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andrew G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Daniel Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Cheryl Duffy",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danny M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Tiffany Nelson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Eric W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "John Phillips",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Paula K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Lisa Bishop",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Joseph Russell",
        "service_type": "Garage Door Opener",
        "tech_name": "Nathan C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Brandi Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Rebecca Cain",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sarah L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Richard Martinez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Melinda S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Ryan Torres",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Benjamin H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Marie Russo",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jesse C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Rachel Lopez MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Brandon Craig",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicole R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Nicholas Gonzales",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Luis Thomas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah E.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Brenda Russo",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Christopher Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joan W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Michael Young",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samuel B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Todd Morales PhD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jason G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Alexander Irwin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Vanessa H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Megan Baker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joshua M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Michael Rodriguez",
        "service_type": "Garage Door Services",
        "tech_name": "Victoria L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Thomas Key",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dalton C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Traci Cooke",
        "service_type": "Garage Door Services",
        "tech_name": "Danny W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Claire Delgado",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bethany S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Jonathan Mitchell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Thomas Hill",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Tonya Stephens",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kathleen H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Theresa Bailey",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Roy S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Joseph Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Maria B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Monica Ruiz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tyler M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Yesenia Rice",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chelsea G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Anthony Mason",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Ryan Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Victor W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Bonnie Banks",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Adriana R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Martinez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Eugene S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Daniel Cannon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy E.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Jamie Sandoval",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Chad Aguirre",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Thomas M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Michael Hood",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Kayla Nunez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Vanessa E.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Caroline Payne",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Steve S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Gregory Reed",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tammy B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Craig Sanchez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tiffany C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Kelly Vaughan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Patricia P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Thomas Daniels",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Richard Rogers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Gilbert Hernandez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Richard G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Gina Morris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Megan Cohen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jason H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Erica Watson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jordan H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Ashley Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Patrick G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Amber Dorsey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Diane H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Jesse Bryant",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Victor Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cynthia B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Kelly Caldwell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rachel D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Robert Goodwin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Heather Sweeney",
        "service_type": "Garage Door Services",
        "tech_name": "Diana J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "David Ali",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Janice Y.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Rebecca Mcfarland",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jacqueline S.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Stephanie Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Jamie Nguyen",
        "service_type": "Garage Door Opener",
        "tech_name": "Desiree M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Mathis",
        "service_type": "Garage Door Opener",
        "tech_name": "Ronald R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Kevin Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Alan Wright",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jonathan H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "John Decker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Edward S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Olivia Cook",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Ashley Calhoun",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carlos R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Alexis Collins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "David H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Bruce Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Richard Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Caitlin M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Marc Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Megan Norris",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeanne P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Campbell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Patrick Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Linda Andrews",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Melanie G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Anthony Wells",
        "service_type": "Garage Door Opener",
        "tech_name": "John R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Tina Harris DDS",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Scott M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Ryan Grant",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jerome B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Carla Shaw",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Diana R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Judy Tran",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Cory Ibarra",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Megan S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Haley Ritter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erik S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Anne Gibson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Kathryn Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Thomas H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Dr. Danielle Reyes MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Matthew Murphy",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "John Owens MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Darren H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Chris Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Gloria B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Christine Rivera",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brent F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "David Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nancy F.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Casey Hopkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Tracy P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Robert Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Yvette Coleman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Nicholas Padilla",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alexis M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Brian Castillo",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Miguel G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Holly Serrano",
        "service_type": "Garage Door Insulation",
        "tech_name": "Debbie C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Stacey Jacobs",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lynn P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Julie Hampton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Patricia B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Monica Flores",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Mary Terrell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathy S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Ricardo Campbell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael O.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Michael Walker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Susan H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Sarah Gordon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicole W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Jaime Barr",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amanda B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Amy Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Nicholas Bell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alan H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Bryan Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Nicole Castillo",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joseph C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Jason Odom",
        "service_type": "Garage Door Services",
        "tech_name": "James N.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Andrew Chen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paul D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.8,
        "customer_name": "James Church",
        "service_type": "Garage Door Opener",
        "tech_name": "Miranda C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Emily Gutierrez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicholas F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Barbara Burton",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard O.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Christine Mejia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Erica M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Erica Short",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Julie C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Renee Hoover",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Allison Z.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Scott Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Debra B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Jennifer Shaw",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicholas K.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Robert Bryant",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Tracy Parks",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sheri G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "James Lewis",
        "service_type": "Garage Door Insulation",
        "tech_name": "James V.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Johnny Ramirez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "James Wilkerson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Samuel Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "John Simpson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Thomas Strong",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Kelsey Petty",
        "service_type": "Garage Door Opener",
        "tech_name": "Andrew G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Sarah Spencer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anthony H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Lauren Pineda",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Krista J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Jay Morris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stacey S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Dr. Anthony Garcia Jr.",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Devon H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Marvin Colon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sarah D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Douglas Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Benjamin Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amy T.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Crystal Grant",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Alyssa Mann",
        "service_type": "Garage Door Services",
        "tech_name": "Amber S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Kimberly Baldwin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sara Y.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Lyons",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alyssa D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Donna Phillips",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stephen J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Joseph Choi",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christina B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Sharon Vaughn",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carrie N.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Gary Thomas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sally S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Robert King",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emily R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Sarah Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Holly B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Nicole Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patrick J.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Caitlyn Hughes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Tammy Escobar",
        "service_type": "Garage Door Services",
        "tech_name": "Tyler G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "William Kramer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Benjamin Phelps",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Christine Duran",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Devin K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Susan Burton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Dawn C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Tiffany Jackson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Deanna D.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Brian Harmon",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Thomas H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "David Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Veronica W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Ann Williamson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Peter R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Christopher Thomas",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "David Henderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Arthur S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Sarah Sanchez",
        "service_type": "Garage Door Repair",
        "tech_name": "Tiffany P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Allison Gomez",
        "service_type": "Garage Door Installation",
        "tech_name": "Corey C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Richard Underwood",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacqueline A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "James Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Morgan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Taylor S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Brenda Diaz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy V.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Brandon Dennis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Heather M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Cory Ortiz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephanie W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Taylor Bray",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rick S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Thomas Henderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Olivia R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Debra Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heather M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Mrs. Amanda Ramos DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jonathan N.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Larry Tyler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Eric S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Kevin Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "William D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Julian Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michelle T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Beltran",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patrick D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Cole Anderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Phillip B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Jennifer Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Andrew Thomas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jamie M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Ashley Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Courtney Garner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Karen Whitehead",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Meredith T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Richard Glenn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angela K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "David Campbell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Melanie R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Deborah Gomez DDS",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Eric W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Johnny Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Jeffery Allen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Autumn N.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Dana Henderson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daisy G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Teresa Bennett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Veronica M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Dale Anderson MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "April L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Zachary Pearson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicole L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Brian Robertson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "John Bauer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shannon A.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Emily Farmer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nicole B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "John Sexton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Rebekah Perez",
        "service_type": "Garage Door Repair",
        "tech_name": "Calvin S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Pamela Schneider",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christina M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "James Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Taylor Koch",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sharon M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Daniel Young",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrew S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "William Salazar",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Linda Shelton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Megan Zavala",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Derrick P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "James Collins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christie B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Jamie Figueroa",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Debra B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Charles Roman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heidi B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Laura Brooks",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Deborah Joyce",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Denise L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Richards",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William L.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Michael Esparza",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gabrielle G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Lisa Reeves",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gregory K.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Katherine Dennis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jodi F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Joseph Martin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dawn P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Blake Frost",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patrick M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "David Roberts",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ian G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Jeremy Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Anne Flynn",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew K.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Phillip Williams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John O.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Manuel Roberts",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paul W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Joan Maddox",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephen R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Jorge Lee",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Richard Mahoney",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katherine S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Christine Callahan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Adam A.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Todd Yang",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Joseph R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Paul Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicolas C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Patricia Mckay",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Michelle Robertson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jeremiah G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Jason Reed",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Matthew Bell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Angela M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Krystal Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven Y.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Debra Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael V.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Robin Gibson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicole B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Matthew Sanchez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Richard S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Megan Daniel",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Catherine C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Ashley Brooks",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Molly R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Robin Franklin",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Michael Wallace",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeremy H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Scott Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeanette F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Kerry Jefferson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Megan A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Jillian Morrison",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Martin B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Mrs. Deanna Roberts DVM",
        "service_type": "Garage Door Off Track",
        "tech_name": "John S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Emily Reese",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Richard J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Sharon Mckenzie",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Randall N.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Cynthia Stevens",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Corey M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "James Woods",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bailey S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Brooke Brooks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anthony W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Anthony Fuentes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Jennifer Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephanie J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Sherri Montgomery",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sean D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Thomas Bender",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carolyn C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Johnny Rubio",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Daisy Yu",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Heather G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Jose Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Ariel Kirk",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Brooke Wells",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jacob C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Emily Myers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Danielle P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Kimberly Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sara S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Amanda Greer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Michael Chen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandon D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Erica Calhoun",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Deborah Brewer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rose S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Seth Carter",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Deborah Reynolds",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Melissa Boyd",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samantha G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Anthony Salazar",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emily M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Simmons",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrew D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Jose Frost",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Crystal C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Lindsey Mckay",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Andrew Dawson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Harold Barnes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrew L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Jeanne Castro",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Glenda B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Ronald Klein",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristie L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Angela Martin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ashley M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Patricia Barron",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dennis W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Dennis Allen",
        "service_type": "Garage Door Installation",
        "tech_name": "Grant D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Matthew Barnes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ross P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Craig Goodman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donald B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Melanie Gallagher",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karl R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Katherine Tate",
        "service_type": "Garage Door Services",
        "tech_name": "Austin T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Joshua Davis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bradley S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Bryce Sanchez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Adrienne W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Jerry Buckley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tracey G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "David Hebert",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Cynthia Murphy",
        "service_type": "Garage Door Installation",
        "tech_name": "Allen M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Daniel Henderson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Joshua Whitaker",
        "service_type": "Garage Door Services",
        "tech_name": "Brian L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Jennifer Stanton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jesus S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Jennifer Ali",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Matthew N.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Robert Murphy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kim G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Michael Lopez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Travis C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "David Austin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Diana D.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Calvin M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Robert Mccormick",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Patricia Peterson",
        "service_type": "Garage Door Opener",
        "tech_name": "John G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "David Perez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tony J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Morgan Rodriguez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie L.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Joshua Macdonald",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Linda Cook",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Seth L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Mr. Jose Owens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Patrick Watson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Erika Leonard",
        "service_type": "Garage Door Repair",
        "tech_name": "Micheal G.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Curtis Cortez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandi M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Samuel Thomas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anna C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Lauren Walker",
        "service_type": "Garage Door Repair",
        "tech_name": "Virginia S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Courtney Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Raymond N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Denise Davila",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heather S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "Amanda Reynolds MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jason D.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-11",
        "review_rate": 5.0,
        "customer_name": "Charles Stewart",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Amber Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Leslie G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Cindy Daniels",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jasmine H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Margaret Fox",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Kevin Avila",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shannon W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Daniel Bridges",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Marc Castillo",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Travis E.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Michele Francis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Charles Rice",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kelli J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Houston",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Micheal M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Amy Perez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lindsey A.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Susan Walls",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samantha B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Rachel Nelson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "David Wise",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kimberly W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Jacob Casey",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Benjamin Stephenson",
        "service_type": "Garage Door Repair",
        "tech_name": "Arthur C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Dustin Black",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Samantha W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Amanda Dudley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bradley B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Sharon Burns",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tony J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Donna Hoover",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sandra N.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "John Murray DDS",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicholas B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Meagan Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Susan C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Juan Warner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Martha West",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cassandra K.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Taylor Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Marie Herrera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Peter M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Calvin Bender",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Michelle Salinas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sarah W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Robert Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samantha W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Ronald Hayes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Samantha M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Michael Watts",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ruth B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Tina Cook",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "George A.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Scott Warner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Jason Coleman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David V.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Julie Ramirez",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexandria A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Stephanie Ramsey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Heather Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kyle T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Steven Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Frank R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Susan Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Steven A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "George Patrick",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Booker",
        "service_type": "Garage Door Repair",
        "tech_name": "Sean D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Rebecca Peterson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Preston W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Erik Mcclure",
        "service_type": "Garage Door Services",
        "tech_name": "Kenneth A.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "John Washington",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Patricia L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Jaime Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Veronica O.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Sarah Nelson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kurt P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Moyer",
        "service_type": "Garage Door Off Track",
        "tech_name": "Debra H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Thomas Robertson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Barbara N.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Jennifer Miranda",
        "service_type": "Garage Door Services",
        "tech_name": "Teresa H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Ellen Vasquez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carl R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Deanna Harris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacob W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Samantha Maxwell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cheryl M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Steven Lynch",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Christopher W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Joseph Lang",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Rodney Walter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brittany S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Eric Edwards",
        "service_type": "Garage Door Services",
        "tech_name": "Ariana R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Nancy Cruz",
        "service_type": "Garage Door Services",
        "tech_name": "Shawn M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Stacy Kaufman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Mary K.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Patrick Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ashley O.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Jacob Rojas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Kenneth Norman",
        "service_type": "Garage Door Opener",
        "tech_name": "Joy F.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Kevin Moore",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Lance Burke",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Sean Powers",
        "service_type": "Garage Door Services",
        "tech_name": "Brian M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Corey Sweeney",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Catherine P.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Chelsea Simpson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Jennifer Washington",
        "service_type": "Garage Door Services",
        "tech_name": "Evan H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Mark Wise",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Misty Jackson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shawn S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Karen Cox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Dave Wall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kimberly D.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Shannon Casey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Timothy Mora",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Danielle S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Joshua Murphy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Donald W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Diane Lamb",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cathy B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.8,
        "customer_name": "Katherine Adams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michelle B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Heidi Burns",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Hughes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charlene F.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Michael Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Jessica Thompson",
        "service_type": "Garage Door Services",
        "tech_name": "Lisa S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Michael Lewis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Douglas W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Dawn Newton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jesus H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Kimberly Webster",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michele M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Barry Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anna K.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Brenda Roberts",
        "service_type": "Garage Door Installation",
        "tech_name": "Katie H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Joshua Ortiz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jill E.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Michael Gray",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Raymond T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Susan Stanley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Gregory Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eric C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Brian Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samantha S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "April Martinez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Troy K.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "David Sanders",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carol H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Christopher Glass",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bryan Y.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Leonard Cain",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kara L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Edward Krause",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Angela C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Deborah Tucker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Shawn G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Melissa Gordon",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Wiley",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony L.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Jacob Meza",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "David Hart",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Catherine S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Lisa Cohen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Craig T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Warren Mayer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Maria W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Theresa Glass",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Gina H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Ronald Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Beverly P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Thomas Espinoza",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amanda L.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Margaret Fuller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christine R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Luis Ross",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Austin T.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Richard Hawkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Barron",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gabrielle S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Michael Matthews",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Heather O.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Mindy Gonzalez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bryan M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Robert White Jr.",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Felicia Burns",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicholas M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Cruz",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Micheal T.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Debbie Marquez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kathleen B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Rebekah Deleon DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angel P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "John Russell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rhonda U.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Joshua Fernandez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tara W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Jason Wade MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Gabrielle L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Aaron Molina",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Randall M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Ellis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gregory T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Michael Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shelby C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Heather Allen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Karen Stout",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brandi P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Katherine Collins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Joshua Morrow",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Whitney P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Belinda Merritt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Michele Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Joshua C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Casey Nelson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrew N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Jessica Stanley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Virginia M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "James Carlson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Krystal K.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Julia Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly A.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Mark Simmons",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Claudia T.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Angela Morris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Sarah Maddox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amber P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Sean Ramos",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Latoya Hatfield",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rachel F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Kayla Burch",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jacqueline F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Theresa Wilson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Cynthia Warner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Simmons",
        "service_type": "Garage Door Track Repair",
        "tech_name": "April H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Adam Gibson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kathleen M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "David Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Tina S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Wendy Calderon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Frank Aguilar",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Henry M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Julie Hill",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kaitlin C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.8,
        "customer_name": "Jennifer Benitez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "George H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Brian Barrett",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tyrone O.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Ryan Kim",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Paige V.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Meghan Gardner",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Aaron Simpson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tony J.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Mr. David Cobb",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "William Monroe",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jacob O.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Ashley Long",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nathan C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joel A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Daniel Zimmerman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Rachel Norman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vicki G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Anthony Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nathan F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Debbie Perez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melinda K.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Mackenzie Saunders",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Allison W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Cameron Singh",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Mario Nolan DVM",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laura P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Randy Barber",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jorge H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Kayla Taylor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gary B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Randy Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bradley E.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Amy Green",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stacey H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Donald Green",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kevin E.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Gavin Buckley",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Sara Mitchell",
        "service_type": "Garage Door Opener",
        "tech_name": "Donna G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Webb",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brett W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Kara Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Daniel C.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Beth Cortez",
        "service_type": "Garage Door Opener",
        "tech_name": "Alan W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-17",
        "review_rate": 5.0,
        "customer_name": "Alisha Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John K.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Melissa Watson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Timothy Gonzales",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brianna G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Samantha Arnold",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda A.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Sandra Holt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Angela H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "William Liu",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Jason Yu",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brandon B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Jeffery Wood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Evan Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Kyle Vega",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Megan H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Lisa Tucker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paige S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Tammy Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Whitney Gill",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Kelly Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Victoria Miller",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Drew H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Kristy Fleming",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Donald B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "William Young MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sharon K.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Amanda Simon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Mark Valencia",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Daniel J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Marcus Mcpherson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Johnston",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Diane S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Sydney Waters",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Darren T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Mark Graham",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Julie H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Rick Curry",
        "service_type": "Garage Door Opener",
        "tech_name": "Rebecca W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Dr. Nicholas Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Tamara A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Jason Martin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Joseph Thomas",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amy F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Bonnie Trujillo",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stephanie B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Allison Garcia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eduardo S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Cassandra Cobb",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Kimberly Graham",
        "service_type": "Garage Door Repair",
        "tech_name": "William M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Emily Hayes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Brendan Wilson Jr.",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kim C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Lindsey Harvey",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melissa F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Dawn Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrew P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "William Wilson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anna T.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Susan Mitchell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Leonard S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Craig Martin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Hayley G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Adam Cooper",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Wayne M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Phillip Shea",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Juan B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Rachel Clark",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Darlene L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Albert Fisher",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Amanda Kelly",
        "service_type": "Garage Door Opener",
        "tech_name": "Aaron H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Jay Dawson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lauren M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Christopher Hill",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer I.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "John Frazier",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jared R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Bobby Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Deanna P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Joseph Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lauren A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Kelly Vazquez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christopher W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Ryan Lozano Jr.",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brianna H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Nicole Dickerson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tamara M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Nancy Henson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melody P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Evelyn Pena",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicholas F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Stephanie Edwards",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Deborah B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Lisa Walton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Terri L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Andrew Parks",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amber H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Brittany Hall",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Diane Silva",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Jacob Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shawn D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Dean Chavez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Richard Chung",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Colton T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Nichole Walker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Jared Williamson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Donald W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Andrea Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Alexis Mitchell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Kelly Velasquez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shaun S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "John Escobar",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Sandra Gilmore",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Tasha Turner",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brenda B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Michael Moss",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Eric Nelson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeff M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Jose Owens",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alyssa S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Michael Burke",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Reginald R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Andrea Santiago",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brenda V.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Connie Mcdonald",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Annette H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Warner",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carrie S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Kristin Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Patrick Simmons",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Amanda Robertson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Taylor R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Audrey Floyd",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kyle C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Laura Huang",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gregory A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Michael Miller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Adam Cline",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Lauren Barnes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alicia H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Tyler Baker MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Colton R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Latasha Nelson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa O.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Kristen Keller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heidi T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Mark Chavez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Duane H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Higgins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Lisa King",
        "service_type": "Garage Door Services",
        "tech_name": "Colleen D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Logan Sparks",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lindsey C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "David Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Luis Soto",
        "service_type": "Garage Door Services",
        "tech_name": "Deborah J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Carla Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Frances H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Lisa Mcfarland",
        "service_type": "Garage Door Opener",
        "tech_name": "Kyle B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Peggy Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Loretta D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Dustin Bird",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amber D.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Andrea Chapman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Adam Davila Jr.",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrew L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Hannah Edwards",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Johnny C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Jenna Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gloria B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "David Garza",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "John Nguyen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Angel Sparks",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Donna C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Andrea Jenkins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brian M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Kristin Fisher",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Mejia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Cynthia Schneider",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gregory F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Kylie Winters",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jordan J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Derrick Conley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Lee Hammond",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brenda G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "James Oneal",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Russell Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Connie A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Carla Beasley",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Matthew J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Daniel House",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brandon J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Jason Morales",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Gloria C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Zachary King",
        "service_type": "Garage Door Services",
        "tech_name": "Teresa D.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Todd Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chris F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Scott Reed",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Michael Jimenez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sierra T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Sheila Larson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tina D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Leslie Maxwell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "William H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Erin Rivera",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Matthew Lee",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rachel M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Brian Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gabriella V.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Tanya Kramer",
        "service_type": "Garage Door Services",
        "tech_name": "Paula S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "James Herrera",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amber G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Calvin Rollins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Miss Tiffany Benton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jessica H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Nicholas Wright",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Danielle B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Nathan Collins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Emily M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Julia Malone",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shawn M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Miss Anna Roberts",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sherry L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Mr. Christopher Hunter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Richard C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Jonathan Cohen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joshua R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Brett Eaton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Charles W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Joseph Nelson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Devin Morales",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Linda G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Miguel Warren",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sylvia A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Ann Chapman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sergio A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Julie Carr",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Debra H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Carmen Hernandez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gail H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Lauren Vasquez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anthony P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Dylan Lewis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Albert R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Gregory Rivera",
        "service_type": "Garage Door Services",
        "tech_name": "Alexis H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Phyllis Rocha",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Diaz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrea L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Jordan Chavez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeremy H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Angela Mora",
        "service_type": "Garage Door Installation",
        "tech_name": "Martha T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Holt",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Desiree S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Tonya Rhodes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tara L.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Terry Landry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Thomas Evans",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeremy S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Tiffany Benson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kyle W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Stanley Vargas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "William O.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Brittany Bird",
        "service_type": "Garage Door Repair",
        "tech_name": "Todd M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Jeremy Wade",
        "service_type": "Garage Door Insulation",
        "tech_name": "Stacy M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Wendy Herring",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ryan R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Anthony Cline",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Jose Henson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrea B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Monica Herrera",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Wesley R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Michael Wright",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebecca T.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "David Sanders",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kenneth J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Jeanne Garcia DVM",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kimberly M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Jennifer Chang",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jose T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Dawn Holmes",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eric P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Carol Miles",
        "service_type": "Garage Door Installation",
        "tech_name": "Stacey S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Sabrina Palmer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Faith C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Robert Kim",
        "service_type": "Garage Door Off Track",
        "tech_name": "Frank V.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Cindy Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Beth S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Emily Monroe",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Anna Brooks",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Beth K.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Jenna Thompson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Craig M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Morgan Hansen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kerri W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Tamara Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly O.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Katherine Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shirley S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Tracy Bell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jamie T.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Natalie Lopez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicole D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Kimberly Ford",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Pamela F.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Tara Ortiz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Patrick Hernandez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mary M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "John Wheeler",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bryce T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Miranda Walker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Edward S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Daisy Castaneda",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sharon C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "James Powell",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brianna G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Danielle Acevedo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Stephanie Peters",
        "service_type": "Garage Door Installation",
        "tech_name": "Rebecca S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Benjamin Bailey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Bruce J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Sarah Price",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Miranda M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Darius Harris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sara W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Michelle Anderson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Connie Wilson PhD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Janice R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Kristin Oneal",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer V.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Regina Murray",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sarah Y.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Danielle Mendoza",
        "service_type": "Garage Door Installation",
        "tech_name": "John C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Christopher Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Ashley Garner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-30",
        "review_rate": 5.0,
        "customer_name": "Roy Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Martin M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Courtney Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Melanie D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Christina Bennett",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Mr. Robert Melendez MD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Melissa Walton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Pamela Henderson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kimberly R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Samuel Hansen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michelle T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Jo Garcia",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Thomas Hampton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sabrina C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Joseph Moreno",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dale J.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Alec Moore",
        "service_type": "Garage Door Repair",
        "tech_name": "Casey W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Ashlee Howard",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Scott Kelly",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Danielle B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "James Jackson",
        "service_type": "Garage Door Installation",
        "tech_name": "Beth T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Christopher Marquez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Paula A.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Joseph Gilbert",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Kenneth Hunt",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Nicole Robinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicholas G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Stephanie Olson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rachel P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Tyler Diaz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cody M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Nichole Gates",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Thomas M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Scott Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tracey A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Jay Lopez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Richard T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Misty Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dawn S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Justin Ramsey",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jonathan H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "John Simpson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ann R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "John Swanson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gregory P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Katie Combs",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian Z.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Dawn Middleton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kyle J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Kelly Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Pamela R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Griffin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "George H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Andrew Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrea M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Michael Banks",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Susan W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Bobby Ortega",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tara G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Zachary Baker",
        "service_type": "Garage Door Opener",
        "tech_name": "Frank M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Andrew Howard",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Mary Odom",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Thomas Clark",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christine G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Lauren Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ronald B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Amber Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ann M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Audrey Herring",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mark S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Deborah Perry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Andrew Kelly",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sharon R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Robert Hill",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Brandon Lee",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Marissa B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Michelle Curtis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Emily F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Yvonne Walker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Susan J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Crystal Farley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Michael Wright",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kathryn M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Theresa Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda K.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "James Vance",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Keith W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Ryan Hill",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rachel Y.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Anthony Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rodney G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Jessica Hess",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Shelley S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Matthew Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Albert S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Felicia Fischer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Aaron Garza",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jordan T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Randy Savage",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Louis R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Kevin Lowery",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Teresa Hansen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Connor M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Ashley Parker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Tommy Rangel",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Paula Murray",
        "service_type": "Garage Door Installation",
        "tech_name": "Jeffery B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Alex Ayala",
        "service_type": "Garage Door Off Track",
        "tech_name": "Emily D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Jake Palmer",
        "service_type": "Garage Door Repair",
        "tech_name": "Derek K.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Matthew Sparks",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gregg R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Aaron Kerr",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tracy M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Terri Wright",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kevin M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "David Woodward",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Gregory Gonzalez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alexander P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Karen Clark",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Larry S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Kayla Jacobs",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Craig Y.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Craig Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Stephanie E.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Antonio Baker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sheena L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Robin Duran",
        "service_type": "Garage Door Off Track",
        "tech_name": "Charles A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Kevin Saunders",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Raymond H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Kelly Johnson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rebekah F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Isaiah Erickson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dawn K.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Alexander Hobbs",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bryan H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Samuel Howard",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Phyllis B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Joel Dalton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rachel R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Ryan Dixon",
        "service_type": "Garage Door Off Track",
        "tech_name": "James N.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Brian Delgado",
        "service_type": "Garage Door Insulation",
        "tech_name": "Denise W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "Cynthia Rivera",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeanette G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Cobb",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Mary Russo",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carolyn H.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Gregory Rodriguez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Corey S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Yvonne Romero",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Rodney Jimenez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey E.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Scott Baird",
        "service_type": "Garage Door Installation",
        "tech_name": "Christian J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Sarah Woodard",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Colin N.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Warren Martin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Denise R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Andrew Bradshaw",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark Y.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Lindsay Randolph",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carla M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Matthew Hobbs",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Patricia M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Joseph Nash",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Wendy D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Crystal Sanchez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly A.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Vickie Moon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dylan D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Ray Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Phyllis F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Gina Frank",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacqueline H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Kenneth Proctor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Raymond P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Debbie Simmons",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Bianca Sanford",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sean G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Kristin Finley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ian P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Tyrone Graves",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Wendy R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Lee English",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ethan L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Olsen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelly O.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "John Rhodes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Mallory Patel",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "William E.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Kelly Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Vincent C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Joshua Ward",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sylvia S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "James Buckley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Brooke Olson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Thomas O.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Alexander Christian",
        "service_type": "Garage Door Installation",
        "tech_name": "Aaron A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Jay Lopez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kayla W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Mr. Robert Molina",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Judith G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Rebecca Shea",
        "service_type": "Garage Door Repair",
        "tech_name": "Richard A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Paula Blackburn",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Holly P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Kevin Burns",
        "service_type": "Garage Door Off Track",
        "tech_name": "Reginald J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Betty Edwards",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Vanessa Spencer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jason F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Harold Reed",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Dr. Lucas Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cindy J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Dana Floyd",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Nicholas Arnold",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Pam F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Joshua Stewart",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gregory F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Jamie Robinson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Michael Gomez",
        "service_type": "Garage Door Opener",
        "tech_name": "Bryan S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Sharon Orozco",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Justin E.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.8,
        "customer_name": "Sherri Sanders",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle A.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Matthew Cross",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Juan Bray",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jon S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Gary Wolf",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katrina N.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Bryan Warner",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tracy G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Kelly Abbott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Christopher Booker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Savannah H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Xavier Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Leah B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Stacey Green",
        "service_type": "Garage Door Insulation",
        "tech_name": "Suzanne T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Peter Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paul J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Justin Cooper",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Linda W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Robert Hunter",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Aaron C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Heather A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Wiley",
        "service_type": "Garage Door Opener",
        "tech_name": "Brittany W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Hannah Wise",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Zachary R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Stanley Marquez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Eric W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Wendy Hall MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brandon W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Bradley Norton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Lee Griffith",
        "service_type": "Garage Door Off Track",
        "tech_name": "David H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "David Robertson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Dennis B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Travis Mathis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Maria A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Annette Wright MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Raymond M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Jeremiah Bishop",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Tanya Mcdaniel",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bonnie A.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Todd Simon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eric E.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Aimee Ware",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Keith S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Eric Case",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tamara W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Brian Taylor DVM",
        "service_type": "Garage Door Repair",
        "tech_name": "Heather C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Ashley Becker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "David K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Renee Wilcox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Erica M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Sandra Delgado",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Carl B.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Luis Dunn",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jonathan C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Erika Oliver",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Richard Castillo",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicholas W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Andrew Gross",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Teresa M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Sara Harding",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jacob W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Robert Moody",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Sara Booth",
        "service_type": "Garage Door Insulation",
        "tech_name": "Louis P.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Matthew Hale",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Carlos H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Dr. Meagan Smith DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "David Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shannon H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Emily Peterson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adrian T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "David Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lisa R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Tami Bates",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christina M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Brittany Carter",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Grant R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Ann Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sarah M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Curtis Douglas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eric L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "John Crosby",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Melissa Silva",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Mr. Patrick Simpson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Keith J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Derek Henderson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Bryce H.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "William Schwartz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gary E.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Lance King",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Mr. Michael Turner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Rebecca Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Lauren Drake",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Veronica Barnes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nicholas M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Jessica Kennedy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Karen M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Robert Fletcher",
        "service_type": "Garage Door Opener",
        "tech_name": "Hannah M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Zachary Wilkinson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Trevor A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Frances Diaz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Douglas P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Monica Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Charles R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Robert Moody",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gerald M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Eric Ross",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Thomas A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Andrew Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrew H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Peggy Cohen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katherine N.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Christine Odonnell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nancy F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Kramer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jason J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Steven Navarro",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Emily Carrillo",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Timothy B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Preston Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Francisco G.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Jesse Rowland",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Danielle W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Laura Rice",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley N.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Jeffery Bryant",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anna R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Vicki Jensen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Brian Burke",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ricardo S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Hannah Mitchell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "James C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Michael Stewart",
        "service_type": "Garage Door Installation",
        "tech_name": "Sharon G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Lawrence Mendoza",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andrew H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Mark Garrett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Felicia C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Stephanie Flores",
        "service_type": "Garage Door Opener",
        "tech_name": "Lori J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Mark Moore",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lindsay V.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Gabriel Bauer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brenda C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Michael Robinson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Mary Lopez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacqueline A.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Michael Barnes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Pamela R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Cory Jones DVM",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Erika G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Michael Hale",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gregory G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Gregory Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Maria Sanchez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Laura F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Lindsey King",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gregory G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Brianna Farrell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Danielle M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Brenda Parker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jose Y.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Lisa Villarreal",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Austin C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Samuel White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Gary Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Courtney V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Chad Palmer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ruben K.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "John Barnes",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Theresa C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Thomas Bryan",
        "service_type": "Garage Door Repair",
        "tech_name": "Olivia J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Sierra Taylor",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert V.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Douglas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert F.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Keith Oliver",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Laura Colon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joshua S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Tyler Mathews",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Bailey F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Jose Gallegos",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Evelyn M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Brian Holland",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristen S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Christian Shepherd",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Sandra Bennett MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffery M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-18",
        "review_rate": 5.0,
        "customer_name": "Stephen Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Isaac T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Keith Young",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michele N.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Carl Watts",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gordon M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Jason Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Belinda H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Bradley Watson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Randall S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Tiffany Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brandon R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "David Huang",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Charlene P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Jasmine Sims",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeremy K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Steven Davis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas Y.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Katherine Gonzalez",
        "service_type": "Garage Door Repair",
        "tech_name": "Robert C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Meyer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Guerra",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Adam D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Kathleen Murray",
        "service_type": "Garage Door Installation",
        "tech_name": "Diana P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Timothy Garza",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sheila C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Lindsey Nielsen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Helen P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Peter Carr",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea K.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Benjamin Cherry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gregory P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Jessica Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christina L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Bruce Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amanda V.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Richard Rhodes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashlee H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Holly Simmons",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emily J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Benjamin Rodriguez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brenda B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Jose Meadows",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melissa D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Amber Snow",
        "service_type": "Garage Door Insulation",
        "tech_name": "Susan M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Mark Oconnor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Paula W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Patrick Henry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lauren R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Rachel Sellers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Valerie R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "James Porter",
        "service_type": "Garage Door Opener",
        "tech_name": "Virginia C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Kristine Booth",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Melanie Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rebecca R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Samantha Anderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Wanda M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Michael White",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Spencer",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Max Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Timothy T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Jessica Oconnor",
        "service_type": "Garage Door Installation",
        "tech_name": "James J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Tiffany Martinez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jason G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Kathy Kerr",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Fred Wilkinson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Samantha T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Christina Mathis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jerome L.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Black",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Heather A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Edward Ramos",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "William J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Travis Thompson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bryan R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Nathan Gould",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Taylor M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Laura Martinez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Virginia Buckley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Allison T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Dylan Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Linda C.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Joshua Meyers",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Marc D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Kayla Ramirez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Tracey Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Robin G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Andrew Chavez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Priscilla S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Ryan Armstrong",
        "service_type": "Garage Door Services",
        "tech_name": "Heather J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Brian Cortez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Veronica A.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Michael Howard",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Jose Miller",
        "service_type": "Garage Door Services",
        "tech_name": "Toni H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Justin Nelson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Desiree M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Jacob Mora",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephen N.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Abigail Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Leslie B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Matthew Bautista",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Erica Martin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Judy Holmes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alexander D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Dr. Kayla Boyer",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Luis R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Nancy Torres",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Colin Taylor",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Spencer H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Alexis Jackson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amanda T.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Torres",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mark M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Shaun Russell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ruth M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Mr. Michael Ward",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Lance Chavez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Kathleen Thompson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tyler D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Anthony Small",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tiffany H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Michelle Bowman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Donald B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Robert Glenn",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stacey G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Stephanie Hurst",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly T.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Valdez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kyle M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Cynthia Benson MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrea B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Joshua Bolton",
        "service_type": "Garage Door Services",
        "tech_name": "Zachary D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Sara Lin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Julie E.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Amanda Holmes",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Douglas Cook",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brian H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Amanda Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Guy Porter",
        "service_type": "Garage Door Installation",
        "tech_name": "Tony H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Allison Pratt",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Maria S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Madison Arnold",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Adam D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Craig Nguyen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jasmine C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Brenda Horn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeffrey W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Casey Holloway",
        "service_type": "Garage Door Installation",
        "tech_name": "Kevin S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Ashley Silva",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Robert Snyder",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Philip D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Peter Price",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shelley T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Hailey Gutierrez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rachel W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Mitchell Bradford",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cody B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Eric Cummings",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amanda J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "John Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kylie M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Laura Gardner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Adam P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Jeanette Wallace",
        "service_type": "Garage Door Opener",
        "tech_name": "Connor V.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Robin Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Carrie Hernandez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Patrick L.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Samuel Kim",
        "service_type": "Garage Door Opener",
        "tech_name": "David W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Gary Barnes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley W.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Rebecca Jennings",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Karen J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Andrew Jacobs",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Larry H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Anthony Mendoza",
        "service_type": "Garage Door Opener",
        "tech_name": "Katie H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Daniel Ross",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lacey S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Brandon Kim",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Anthony M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Ryan Christensen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Daniel S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Barbara Coleman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gary O.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Charles Knight",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Drew D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Sarah Holt",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bobby P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Patterson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jacob T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Isaiah Estrada",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Audrey M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Kenneth Snyder",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joel B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Jeanne Cruz MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lori C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Alexis Williams",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mike W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Cindy Stephenson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Anthony Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Latoya Cole",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samantha W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Kathryn Turner",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas A.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Lucas Mclaughlin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Tim Porter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kevin D.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Desiree Pena",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robert W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Martinez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Emily T.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Shawn Drake",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tammie M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Katherine Kennedy",
        "service_type": "Garage Door Services",
        "tech_name": "Mark H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Catherine Lester",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Michael Lara",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeremy B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Patricia Price",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Megan D.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Dylan Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-14",
        "review_rate": 5.0,
        "customer_name": "Shannon Acevedo",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sandra A.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Alexandra Peck",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laura B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Emily Hardy",
        "service_type": "Garage Door Repair",
        "tech_name": "John W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Reynolds",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gary C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Cindy Munoz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Jessica Roman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffrey B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Rebecca Jenkins DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jared M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Michael Ruiz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gary N.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Joe Macdonald",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alexa H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Jim Clark",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Crystal R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Tonya Logan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dale J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "John Chandler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brooke N.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Andrew Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Karen W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Daniel Atkinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lacey W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Anna Cooper",
        "service_type": "Garage Door Repair",
        "tech_name": "Andrew W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Mary Hooper",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dwayne A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Glenda Powell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christine F.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Cindy Sampson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Courtney W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Matthew Duffy",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Jenny Foster",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica V.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Curtis Nelson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Angela Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stacy G.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Brian Mitchell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ian B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Linda Morrow",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Maria M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Dustin Thornton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shannon M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "James Mueller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrea W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Ross",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Nathan Rogers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Reginald N.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Antonio Bates",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dylan H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Scott Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paula T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Charles Nguyen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Destiny R.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Sabrina Morales",
        "service_type": "Garage Door Installation",
        "tech_name": "Patrick S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Kimberly Cohen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Emma S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Sarah Stewart",
        "service_type": "Garage Door Opener",
        "tech_name": "Larry L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Rodney Briggs",
        "service_type": "Garage Door Services",
        "tech_name": "Amber B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Patrick R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Duane Reyes",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Andrew Cole",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Amanda R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Scott Clay",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Samantha J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Earl Mahoney",
        "service_type": "Garage Door Installation",
        "tech_name": "Patricia H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Timothy Lara",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Adam C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Emily Roberts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tricia G.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "John Pacheco",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "James C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Bruce Reid",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "John G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "David Reese",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kevin S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Melissa Gomez",
        "service_type": "Garage Door Services",
        "tech_name": "Phillip C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Victoria Ruiz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Marcus R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "James Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Scott B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Tracy Russell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dana O.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Catherine Wright",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lisa W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Heather Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Heather F.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Terri Norris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Austin P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Dennis Lopez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sharon J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Spencer Figueroa",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Judith B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Hayley Reynolds",
        "service_type": "Garage Door Services",
        "tech_name": "Savannah H.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Jill Jimenez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lindsey S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Natalie Taylor",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amanda C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "William Thomas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Meredith O.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Benjamin Callahan",
        "service_type": "Garage Door Services",
        "tech_name": "Heather P.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Angela Gonzales",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Monica M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
